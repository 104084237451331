import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';
import { Box, Container } from '@material-ui/core';
import ApprovalListResults from 'src/components/approvals/ApprovalListResults';
import ApprovalListToolbar from 'src/components/approvals/ApprovalListToolbar';
import ApiService from 'src/services/ApiService';
import { BackDropContext } from 'src/utils/BackdropContext';

const ApprovalList = () => {
  const navigate = useNavigate();
  const [approvals, setApprovals] = useState([]);
  const [displayedApprovals, setDisplayedApprovals] = useState([]);
  const cookies = new Cookies();
  const user = cookies.get('umf_user');
  const currentUser = cookies.get('umf_user');
  const backdropContext = useContext(BackDropContext);

  const navigateToDashboard = useCallback(() => {
    navigate('/app/dashboard');
  }, [navigate]);

  const loadApprovals = useCallback(async () => {
    backdropContext.setShowBackdrop(true);
    try {
      const { status, data } = await ApiService(user.token).GetApprovals({
        userType: user.userType
      });
      if (status === 200) {
        setApprovals(data.map((approval) => approval));
        setDisplayedApprovals(data.map((approval) => approval));
      }
      backdropContext.setShowBackdrop(false);
    } catch (error) {
      backdropContext.setShowBackdrop(false);
      alert(error);
    }
  }, [user.token, user.userType]);

  const updateApprovals = useCallback((results) => {
    setDisplayedApprovals(results);
  }, []);

  useEffect(() => {
    if (currentUser.userType === Constants.CUSTOMER) navigateToDashboard();
    if (
      currentUser.userType === Constants.AGENT &&
      !currentUser.agentPrivileges.approvals
    )
      navigateToDashboard();
    if (
      currentUser.userType === Constants.MANAGER &&
      !currentUser.managerPrivileges.approvals
    )
      navigateToDashboard();
  }, [
    currentUser?.agentPrivileges?.approvals,
    currentUser?.managerPrivileges?.approvals,
    currentUser.userType,
    navigateToDashboard
  ]);

  useEffect(() => {
    loadApprovals();
  }, [loadApprovals]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ApprovalListToolbar
            onSearch={updateApprovals}
            approvals={approvals}
          />
          <Box sx={{ pt: 3 }}>
            <ApprovalListResults _approvals={displayedApprovals} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ApprovalList;
