import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';

import { Box, Button, Container, Link, Typography, Card, CardContent } from '@material-ui/core';

const RegisterOptions = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get('umf_user');

  useEffect(() => {
    if (user.userType !== Constants.ADMIN) navigate('/app/dashboard');
  }, []);

  const routeToRegisterAgent = () => {
    navigate('agent', { state: {} });
  };

  const routeToRegisterCustomer = () => {
    navigate('customer', { state: {} });
  };

  const navigateBack = () => {
    alert('nav back');
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Register | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="lg">
          <Formik initialValues={{}}>
            {({ errors, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 10 }}>
                  <Typography color="textPrimary" variant="h4">
                    Type of Account to Register?
                  </Typography>
                </Box>
                <Box
                  sx={{
                    py: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: 5,
                    mb: 10
                  }}
                >
                  <Card
                    sx={{
                      width: 1000,
                      height: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      px: 10
                    }}
                  >
                    <CardContent>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={routeToRegisterAgent}
                      >
                        Agent
                      </Button>
                    </CardContent>
                  </Card>

                  <Card
                    sx={{
                      width: 1000,
                      height: 200,
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      px: 10
                    }}
                  >
                    <CardContent>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={routeToRegisterCustomer}
                      >
                        Customer
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={navigateBack}
                >
                  Back
                </Button>
                {/* <Link
                  component={RouterLink}
                  onClick={navigateBack}
                  variant="h6"
                  underline="hover"
                >
                  Go Back
                </Link> */}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default RegisterOptions;
