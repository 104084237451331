import { Box, Typography, Card, CardContent } from '@material-ui/core';

const RenderFallback = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
      }}
    >
      <Card>
        <CardContent>
          <Typography color="textPrimary" variant="h6" sx={{ margin: 'auto', fontSize: '18px' }}>
            Please wait...
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RenderFallback;
