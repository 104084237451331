import 'react-perfect-scrollbar/dist/css/styles.css';
import { useMemo, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine';
import { BackDropContext } from 'src/utils/BackdropContext';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';

const App = () => {
  const content = useRoutes(routes);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('Title');
  const [modalText, setModalText] = useState('Content');
  const Alert = (alertParams) => {
    setShowModal(alertParams?.showModal);
    setModalTitle(alertParams?.success ? 'Success' : 'Error');
    setModalText(alertParams?.modalText);
  };

  const backdropContext = useMemo(
    () => ({
      setShowBackdrop,
      Alert
    }),
    [showBackdrop, showModal, modalTitle, modalText]
  );

  const AlertModal = () => {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4
    };

    return (
      <div>
        <Modal
          open={showModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {modalTitle}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {modalText}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button variant="contained" onClick={() => setShowModal(false)}>
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <GlobalStyles />
        <BackDropContext.Provider value={backdropContext}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <AlertModal></AlertModal>
          {content}
        </BackDropContext.Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
