import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import RenderFallback from 'src/components/RenderFallback';
import App from './App';
import './polyfill';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import { Store } from './services/DataStoreService/store/Store';

ReactDOM.render(
  <Suspense fallback={<RenderFallback />}>
    <BrowserRouter>
      <Provider store={Store}>
        <App />
      </Provider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
