const getSubject = (approval) => {
  if (Object.keys(approval).includes('branchName')) return approval.branchName;
  if (
    Object.keys(approval).includes('agentBranch') ||
    Object.keys(approval).includes('managerBranch')
  )
    return `${approval?.firstName} ${approval?.middleName} ${approval?.lastName}`;
  if (Object.keys(approval).includes('accountHolders'))
    return `${approval?.members?.reduce(
      (acc, curr, index) =>
        acc +
        `${curr?.firstName} ${curr?.middleName} ${curr.lastName}${
          (index !== approval?.members?.length - 1 && ', ') || ''
        }`,
      ''
    )}`;
};

export const getApprovalType = (approval) => {
  if (Object.keys(approval).includes('branchName')) return 'BRANCH';
  if (Object.keys(approval).includes('agentBranch')) return 'AGENT';
  if (Object.keys(approval).includes('managerBranch')) return 'MANAGER';
  if (Object.keys(approval).includes('accountHolders')) return 'ACCOUNT';
  if (Object.keys(approval).includes('transactionType')) return 'TRANSACTION';
};

export const parseApprovalDescription = (approval) => {
  if (Object.keys(approval).includes('transactionType'))
    return `Approve ${getApprovalType(
      approval
    ).toLocaleLowerCase()} on account ${approval.accountId}`;

  return `Approve ${getApprovalType(
    approval
  ).toLocaleLowerCase()} registration for ${getSubject(approval)}`;
};

export const getFullName = (source) => {
  return `${source.firstName}${
    source.middleName ? ' ' + source.middleName + ' ' : ' '
  }${source.lastName}`;
};
