import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

const BranchListResults = ({ branches, ...rest }) => {
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    let newSelectedBranchIds;

    if (event.target.checked) {
      newSelectedBranchIds = branches.map((branch) => branch.id);
    } else {
      newSelectedBranchIds = [];
    }

    setSelectedBranchIds(newSelectedBranchIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedBranchIds.indexOf(id);
    let newSelectedBranchIds = [];

    if (selectedIndex === -1) {
      newSelectedBranchIds = newSelectedBranchIds.concat(selectedBranchIds, id);
    } else if (selectedIndex === 0) {
      newSelectedBranchIds = newSelectedBranchIds.concat(selectedBranchIds.slice(1));
    } else if (selectedIndex === selectedBranchIds.length - 1) {
      newSelectedBranchIds = newSelectedBranchIds.concat(selectedBranchIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBranchIds = newSelectedBranchIds.concat(
        selectedBranchIds.slice(0, selectedIndex),
        selectedBranchIds.slice(selectedIndex + 1)
      );
    }

    setSelectedBranchIds(newSelectedBranchIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const navigateToBranchDetails = (event, branch) => {
    navigate('/app/register/branch', { state: branch });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Branch Name</TableCell>
                <TableCell>Branch Location</TableCell>
                <TableCell>Branch Country</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branches.slice(0, limit).map((branch) => (
                <TableRow
                  hover
                  key={branch._id}
                  selected={selectedBranchIds.indexOf(branch._id) !== -1}
                  onClick={(event) => navigateToBranchDetails(event, branch)}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {branch?.branchName.toTitleCase()}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{branch?.branchLocation.toTitleCase()}</TableCell>
                  <TableCell>{`${branch?.branchCountry}`}</TableCell>
                  <TableCell>{moment(branch.createdAt).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={branches.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

BranchListResults.propTypes = {
  branches: PropTypes.array.isRequired
};

export default BranchListResults;
