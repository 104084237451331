import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { AccountTypeConverter } from 'src/utils/Converters';
import { getFullName } from 'src/utils/Parsers';

const AccountListResults = ({ accounts, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const navigateToAccountDetails = (accountId, index) => {
    navigate('/app/account/details', {
      state: {
        accountId,
        accountHolders: accounts[index].accountHolders,
        accountBalance: accounts[index].accountBalance
      }
    });
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleAccountClick = (accountId, index) => {
    navigateToAccountDetails(accountId, index);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Account Holder(s)</TableCell>
                <TableCell>Account ID</TableCell>
                <TableCell>Account Type</TableCell>
                <TableCell>Account Balance</TableCell>
                <TableCell>Minimum Balance</TableCell>
                <TableCell>Creation date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.slice(page * limit, page * limit + limit).map((account, index) => (
                <TableRow
                  hover
                  key={account.id}
                  onClick={() => handleAccountClick(account.accountId, index)}
                  sx={{ ':hover': { cursor: 'pointer' } }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {account.accountType === 'CORPORATE_ACCOUNT'
                          ? account.companyName
                          : account.members.map((member, index) => {
                              if (index !== account.members.length - 1) {
                                return `${getFullName(member)} & `;
                              }
                              return getFullName(member);
                            })}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{account?.accountId}</TableCell>
                  <TableCell>{`${AccountTypeConverter(account?.accountType)}`}</TableCell>
                  <TableCell>
                    <span>&#8373; &nbsp;</span>
                    <NumericFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType="text"
                      value={account?.accountBalance}
                      thousandSeparator=","
                    />
                  </TableCell>
                  <TableCell>
                    <span>&#8373; &nbsp;</span>
                    <NumericFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType="text"
                      value={account?.minimumBalance}
                      thousandSeparator=","
                    />
                  </TableCell>
                  <TableCell>{moment(account?.createdAt).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={accounts.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

AccountListResults.propTypes = {
  accounts: PropTypes.array.isRequired
};

export default AccountListResults;
