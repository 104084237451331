import { Box, Button, Card, CardContent, TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Constants from 'src/utils/Constants';
import Cookies from 'universal-cookie';

const AgentListToolbar = (props) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get('umf_user');

  const navigateToAccountOptions = () => {
    navigate('/app/register/agent');
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {user.userType === Constants.ADMIN ? (
          <Button color="primary" variant="contained" onClick={navigateToAccountOptions}>
            Add Teller
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default AgentListToolbar;
