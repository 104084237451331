const AccountTypeConverter = (accountType) => {
  return {
    PERSONAL_ACCOUNT: 'PERSONAL ACCOUNT',
    JOINT_ACCOUNT: 'JOINT ACCOUNT',
    CORPORATE_ACCOUNT: 'CORPORATE ACCOUNT',
    WITHDRAWAL_TRANSACTION: 'WITHDRAWAL',
    DEPOSIT_TRANSACTION: 'DEPOSIT'
  }[accountType];
};

const TransactionTypeConverter = (transactionType) => {
  return {
    DEPOSIT_TRANSACTION: 'Deposit Transaction',
    WITHDRAWAL_TRANSACTION: 'Withdrawal Transaction'
  }[transactionType];
};

export { AccountTypeConverter, TransactionTypeConverter };
