import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const CountCard = (props) => (
  <Card {...props}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: 'space-between',
          height: '120px'
        }}
      >
        <Grid item sx={{ marginTop: '20px' }}>
          {!props.disabled ? (
            <>
              <Typography color="#fff" gutterBottom variant="h6">
                {props.totalname}
              </Typography>
              <Typography color="#fff" variant="h3">
                {props.showcurrency ? <span>&#8373; &nbsp;</span> : null}
                {props.totalcount}
              </Typography>
            </>
          ) : null}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default CountCard;
