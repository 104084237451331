import axios from 'axios';

const ApiService = (token) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response && error?.response?.status === 401) {
        window.location = '/login';
      }
      if (error?.response && error?.response?.data)
        return Promise.reject(error?.response?.data);
      return Promise.reject(
        (error?.response && error?.response?.message) ||
          'Unable to reach unitrans servers'
      );
    }
  );
  return {
    AxiosInstance: axiosInstance,
    RegisterAccount: (body) => axiosInstance.post('/account', body),
    GetAccounts: (params) => axiosInstance.get('/account', { params }),
    RegisterCustomer: (body) => axiosInstance.post('/customer', body),
    GetCustomers: (params) => axiosInstance.get('/customer', { params }),
    GetCustomerProfile: (params) =>
      axiosInstance.get('/customer/profile', { params }),
    UpdateCustomer: (body) => axiosInstance.patch('/customer', body),
    RegisterAgent: (body) => axiosInstance.post('/agent', body),
    UpdateAgent: (body) => axiosInstance.patch('/agent', body),
    GetAgents: (params) => axiosInstance.get('/agent', { params }),
    Login: (body) => axiosInstance.post('/auth', body),
    GetTransactions: (params) => axiosInstance.get('/transaction', { params }),
    MakeDeposit: (body) => axiosInstance.post('/transaction/deposit', body),
    MakeWithdrawal: (body) =>
      axiosInstance.post('/transaction/withdrawal', body),
    GetBashBalance: (params) =>
      axiosInstance.get('/transaction/cashbalance', { params }),
    GetAccountTransactions: (params) =>
      axiosInstance.get('/transaction/byaccount', { params }),
    RegisterBranch: (body) => axiosInstance.post('/branch', body),
    UpdateBranch: (body) => axiosInstance.patch('/branch', body),
    GetBranches: (params) => axiosInstance.get('/branch', { params }),
    RegisterManager: (body) => axiosInstance.post('/manager', body),
    UpdateManager: (body) => axiosInstance.patch('/manager', body),
    GetManagers: (params) => axiosInstance.get('/manager', { params }),
    Approve: (body) => axiosInstance.post('/approvals', body),
    Deny: (body) => axiosInstance.post(`/approvals/delete`, body),
    GetApprovals: (params) => axiosInstance.get('/approvals', { params }),
    GetAdmin: (params) => axiosInstance.get('/admin', { params }),
    UpdateAdmin: (body) => axiosInstance.patch('/admin', body),
    UpdateUserPrivileges: (body) => axiosInstance.patch('/privileges', body),
    DeleteUser: (params) => axiosInstance.delete('/account', { params: params})
  };
};

export default ApiService;
