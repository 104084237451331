import { Box, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';

const ManagerListToolbar = (props) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get('umf_user');

  const navigateToAccountOptions = () => {
    navigate('/app/register/manager');
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {user.userType === Constants.ADMIN ? (
          <Button color="primary" variant="contained" onClick={navigateToAccountOptions}>
            Add manager
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default ManagerListToolbar;
