import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { TransactionTypeConverter } from '../../utils/Converters';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';

const TransactionListResults = React.forwardRef(({ transactions, ...rest }, forwardRef) => {
  const [limit, setLimit] = useState(rest.paginate ? 10 : transactions.length);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');
  const [isViewableAccountDetails, setIsViewableAccountDetails] = useState(false);

  useEffect(() => {
    if (
      (currentUser.userType === Constants.AGENT && currentUser?.agentPrivileges?.accounts) ||
      (currentUser.userType === Constants.MANAGER && currentUser?.managerPrivileges?.accounts) ||
      currentUser.userType === Constants.ADMIN
    ) {
      setIsViewableAccountDetails(true);
    }
  }, [
    currentUser?.agentPrivileges?.accounts,
    currentUser?.managerPrivileges?.accounts,
    currentUser.userType
  ]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const navigateToAccountDetails = (accountId, index) => {
    navigate('/app/account/details', {
      state: {
        accountId,
        accountBalance: transactions[index]?.account.accountBalance
      }
    });
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table ref={forwardRef}>
            <TableHead>
              <TableRow>
                <TableCell>Account ID</TableCell>
                <TableCell>Account Holders</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell>Transaction Amount</TableCell>
                <TableCell>Transaction Teller</TableCell>
                <TableCell>Transaction Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.slice(page * limit, page * limit + limit).map((transaction, index) => (
                <TableRow
                  hover={isViewableAccountDetails}
                  key={transaction.id}
                  onClick={() =>
                    isViewableAccountDetails &&
                    navigateToAccountDetails(transaction.accountId, index)
                  }
                  sx={{ ':hover': { cursor: 'pointer' } }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {transaction.accountId}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'flex-start',
                        display: 'flex'
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {transaction.customerName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{TransactionTypeConverter(transaction.transactionType)}</TableCell>
                  <TableCell>
                    <span>&#8373; &nbsp;</span>
                    <NumericFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType="text"
                      value={transaction.transactionAmount}
                      thousandSeparator=","
                    />
                  </TableCell>
                  <TableCell>{transaction.transactionAgentName}</TableCell>
                  <TableCell>{moment(transaction.createdAt).format('DD/MM/YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {rest.paginate ? (
        <TablePagination
          component="div"
          count={transactions.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      ) : null}
    </Card>
  );
});

TransactionListResults.propTypes = {
  transactions: PropTypes.array.isRequired
};

export default TransactionListResults;
