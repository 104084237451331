import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import CountCard from 'src/components/dashboard/CountCard';
import ApiService from 'src/services/ApiService';
import Cookies from 'universal-cookie';
import { NumericFormat } from 'react-number-format';
import Constants from 'src/utils/Constants';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [numCustomers, setNumCustomers] = useState(0);
  const [numAccounts, setNumAccounts] = useState(0);
  const [numAgents, setNumAgents] = useState(0);
  const [numTransactions, setNumTransactions] = useState(0);
  const [totalWithdrawalsToday, setTotalWithdrawalsToday] = useState(0);
  const [totalDepositsToday, setTotalDepositsToday] = useState(0);
  const [cashBalance, setCashBalance] = useState(0);
  const [allTimeDeposits, setAllTimeDeposits] = useState(0);
  const [allTimeWithdrawals, setAllTimeWithdrawals] = useState(0);
  const [totalMales, setTotalMales] = useState(0);
  const [totalFemales, setTotalFemales] = useState(0);
  const navigate = useNavigate();

  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');

  const loadData = useCallback(async () => {
    const customers = await ApiService(currentUser.token).GetCustomers({});
    const accounts = await ApiService(currentUser.token).GetAccounts({});
    const agents = await ApiService(currentUser.token).GetAgents({});
    const transactions = await ApiService(currentUser.token).GetTransactions({
      userId: currentUser._id,
      userType: currentUser.userType
    });
    const cashbalance = await ApiService(currentUser.token).GetBashBalance({
      userId: currentUser._id,
      userType: currentUser.userType
    });

    setTotalMales(
      customers.data.filter((customer) => customer.gender === 'Male').length
    );
    setTotalFemales(
      customers.data.filter((customer) => customer.gender === 'Female').length
    );
    setNumCustomers(customers.data.length);
    setNumAccounts(accounts.data.length);
    setNumAgents(agents.data.length);
    setNumTransactions(transactions.data.length);
    setTotalDepositsToday(() =>
      transactions.data
        .filter(
          (transaction) => transaction.transactionType === 'DEPOSIT_TRANSACTION'
        )
        .reduce(
          (total, transaction) => total + transaction.transactionAmount,
          0
        )
    );
    setTotalWithdrawalsToday(() =>
      transactions.data
        .filter(
          (transaction) =>
            transaction.transactionType === 'WITHDRAWAL_TRANSACTION'
        )
        .reduce(
          (total, transaction) => total + transaction.transactionAmount,
          0
        )
    );
    setCashBalance(cashbalance.data.payload);
    setAllTimeDeposits(cashbalance.data?.totalDeposits);
    setAllTimeWithdrawals(cashbalance.data.totalWithdrawals);
  }, [currentUser._id, currentUser.token, currentUser.userType]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (currentUser.userType === Constants.CUSTOMER) {
      navigate('/app/customer/profile', { state: currentUser._id });
    }
  });

  return (
    <>
      <Helmet>
        <title>Dashboard | Unitrans Microfinance</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3} mb={5}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={
                  <NumericFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType="text"
                    value={cashBalance}
                    thousandSeparator=","
                  />
                }
                showcurrency="true"
                totalname={'Cash Balance'}
                sx={{ background: '#6AB187' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={
                  <NumericFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType="text"
                    value={totalDepositsToday}
                    thousandSeparator=","
                  />
                }
                showcurrency="true"
                totalname={'Total Deposits Today'}
                sx={{ background: '#484848' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={
                  <NumericFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType="text"
                    value={totalWithdrawalsToday}
                    thousandSeparator=","
                  />
                }
                showcurrency="true"
                totalname={'Total Withdrawals Today'}
                sx={{ background: '#1F3F49' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={
                  <NumericFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType="text"
                    value={allTimeDeposits}
                    thousandSeparator=","
                  />
                }
                showcurrency="true"
                totalname={'Total All Time Deposits'}
                sx={{ background: '#EA6A47' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={5}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={
                  <NumericFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType="text"
                    value={allTimeWithdrawals}
                    thousandSeparator=","
                  />
                }
                showcurrency="true"
                totalname={'Total All Time Withdrawals'}
                sx={{ background: '#DBAE58' }}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={numCustomers}
                totalname={'Total Customers'}
                sx={{
                  background:
                    currentUser.userType !== Constants.ADMIN
                      ? '#ccc'
                      : '#78BEF2'
                }}
                disabled={currentUser.userType !== Constants.ADMIN}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={numAccounts}
                totalname={'Total Accounts'}
                sx={{
                  background:
                    currentUser.userType !== Constants.ADMIN
                      ? '#ccc'
                      : '#D32D41'
                }}
                disabled={currentUser.userType !== Constants.ADMIN}
              />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={numAgents}
                totalname={'Total Tellers'}
                sx={{
                  background:
                    currentUser.userType !== Constants.ADMIN
                      ? '#ccc'
                      : '#7E909A'
                }}
                disabled={currentUser.userType !== Constants.ADMIN}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={totalMales}
                totalname={'Total Males'}
                sx={{
                  background:
                    currentUser.userType !== Constants.ADMIN
                      ? '#ccc'
                      : '#6AB187'
                }}
                disabled={currentUser.userType !== Constants.ADMIN}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={3} xs={12}>
              <CountCard
                totalcount={totalFemales}
                totalname={'Total Females'}
                sx={{
                  background:
                    currentUser.userType !== Constants.ADMIN
                      ? '#ccc'
                      : '#6AB187'
                }}
                disabled={currentUser.userType !== Constants.ADMIN}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
