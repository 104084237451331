import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';
import AgentListResults from 'src/components/agent/AgentListResults';
import AgentListToolbar from 'src/components/agent/AgentListToolbar';
import ApiService from 'src/services/ApiService';
import { BackDropContext } from 'src/utils/BackdropContext';

const AgentList = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');
  const [agents, setAgents] = useState([]);
  const backdropContext = useContext(BackDropContext);

  useEffect(() => {
    if (currentUser.userType === Constants.CUSTOMER) navigateToDashboard();
    if (
      currentUser.userType === Constants.AGENT &&
      !currentUser.agentPrivileges.agents
    )
      navigateToDashboard();
    if (
      currentUser.userType === Constants.MANAGER &&
      !currentUser.managerPrivileges.agents
    )
      navigateToDashboard();

    loadAgents();
  }, []);

  const loadAgents = async () => {
    backdropContext.setShowBackdrop(true);
    try {
      const { status, data } = await ApiService(currentUser.token).GetAgents(
        {}
      );
      if (status === 200) {
        setAgents(data);
      }
      backdropContext.setShowBackdrop(false);
    } catch (error) {
      backdropContext.setShowBackdrop(false);
      alert(error);
    }
  };

  const navigateToDashboard = () => {
    navigate('/app/dashboard');
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AgentListToolbar />
          <Box sx={{ pt: 3 }}>
            <AgentListResults agents={agents} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AgentList;
