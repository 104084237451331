import { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import { isTokenExpired } from 'src/utils/Validators';

import Cookies from 'universal-cookie';
import AccountListToolbar from 'src/components/account/AccountListToolbar';
import AccountListResults from 'src/components/account/AccountListResults';
import ApiService from 'src/services/ApiService';
import Constants from 'src/utils/Constants';
import { BackDropContext } from 'src/utils/BackdropContext';

const AccountList = () => {
  const [accounts, setAccounts] = useState([]);
  const [displayedAccounts, setDisplayedAccounts] = useState([]);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');
  const backdropContext = useContext(BackDropContext);

  useEffect(() => {
    if (currentUser.userType === Constants.CUSTOMER) navigateToDashboard();
    if (
      currentUser.userType === Constants.AGENT &&
      !currentUser.agentPrivileges.accounts
    )
      navigateToDashboard();
    if (
      currentUser.userType === Constants.MANAGER &&
      !currentUser.managerPrivileges.accounts
    ) {
      navigateToDashboard();
    }

    loadAccounts();
  }, []);

  const loadAccounts = async () => {
    backdropContext.setShowBackdrop(true);
    try {
      const { status, data } = await ApiService(currentUser.token).GetAccounts(
        {}
      );
      if (status !== 200) return;
      if (isTokenExpired(status)) {
        navigateToLogin();
      }
      setAccounts(data);
      setDisplayedAccounts(data);
      backdropContext.setShowBackdrop(false);
    } catch (error) {
      backdropContext.setShowBackdrop(false);
      alert(error);
    }
  };

  const updateAccounts = useCallback((results) => {
    setDisplayedAccounts(results);
  }, []);

  const navigateToLogin = () => {
    navigate('/login');
  };

  const navigateToDashboard = () => {
    navigate('/app/dashboard');
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <AccountListToolbar accounts={accounts} onSearch={updateAccounts} />
          <Box sx={{ pt: 3 }}>
            <AccountListResults accounts={displayedAccounts} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AccountList;
