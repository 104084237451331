import { createSlice } from '@reduxjs/toolkit';

const AppInitialState = {
  datastate: { transactionsResult: [] }
};

const AppStateSlice = createSlice({
  name: 'AppState',
  initialState: AppInitialState,
  reducers: {
    setTransactionsResult: (state, action) => {
      state.datastate.transactionsResult = action?.payload;
    }
  }
});

export const { setTransactionsResult } = AppStateSlice.actions;

export default AppStateSlice.reducer;
