import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';

const CustomerListResults = ({ customers, ...rest }) => {
  const [selectedCustomerIds, ] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const navigateToCustomerDetails = (customerId) => {
    navigate('/app/customer/profile', { state: customerId });
  };

  const handleCustomerClick = (customerId) => {
    if (
      currentUser?.userType === Constants.ADMIN ||
      currentUser?.agentPrivileges?.customerEdits ||
      currentUser.managerPrivileges.customerEdits
    ) {
      navigateToCustomerDetails(customerId);
    }
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers
                .slice(page * limit, page * limit + limit)
                .map((customer) => {
                  return (
                    <TableRow
                      hover
                      key={customer._id}
                      selected={
                        selectedCustomerIds.indexOf(customer._id) !== -1
                      }
                      onClick={() => handleCustomerClick(customer._id)}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar src={customer.avatar} sx={{ mr: 2 }} />
                            
                          <Typography color="textPrimary" variant="body1">
                            {customer.firstName.toTitleCase()}{' '}
                            {customer?.middleName?.toTitleCase()}{' '}
                            {customer.lastName.toTitleCase()}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell>{`${customer.gender}`}</TableCell>
                      <TableCell>{customer.mobile}</TableCell>
                      <TableCell>
                        {moment(customer.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerListResults;
