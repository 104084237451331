import {
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Users as UsersIcon,
  DollarSign as MoneyIcon,
  Database as TransactionsIcon,
  Layers as ApprovalsIcon,
  Briefcase as AccountsIcon,
  Columns as BranchesIcon
} from 'react-feather';

const defaultItems = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    alias: 'dashboard'
  },
  {
    href: '/app/approvals',
    icon: ApprovalsIcon,
    title: 'Approvals',
    alias: 'approvals'
  },
  {
    href: '/app/account',
    icon: AccountsIcon,
    title: 'Accounts',
    alias: 'accounts'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers',
    alias: 'customers'
  },
  {
    href: '/app/agents',
    icon: UsersIcon,
    title: 'Tellers',
    alias: 'agents'
  },
  {
    href: '/app/managers',
    icon: UsersIcon,
    title: 'Managers',
    alias: 'managers'
  },
  {
    href: '/app/branches',
    icon: BranchesIcon,
    title: 'Branches',
    alias: 'branches'
  },
  {
    href: '/app/transactions',
    icon: TransactionsIcon,
    title: 'Reports',
    alias: 'transactions'
  },
  {
    href: '/logout',
    icon: LockIcon,
    title: 'Logout',
    alias: 'logout'
  }
];

const userAccessGenerator = (userType, userPrivileges) => {
  return {
    ADMIN: () => defaultItems,
    MANAGER: () => filterUserAccessByPrivileges(userPrivileges),
    AGENT: () => filterUserAccessByPrivileges(userPrivileges),
    CUSTOMER: () =>
      defaultItems.filter(
        (item) => item.alias === 'logout' || item.alias === 'transactions'
      )
  }[userType]();
};

const filterUserAccessByPrivileges = (userPrivileges) => {
  return defaultItems.filter((item) => {
    if (item.alias === 'dashboard' || item.alias === 'logout') return true;
    return userPrivileges[item.alias];
  });
};

export default userAccessGenerator;
