import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import getInitials from '../../utils/GetInitials';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';

const ManagerListResults = ({ managers, ...rest }) => {
  const [selectedManagerIds, setSelectedManagerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');

  useEffect(() => {}, []);

  const handleSelectAll = (event) => {
    let newSelectedManagerIds;

    if (event.target.checked) {
      newSelectedManagerIds = managers.map((manager) => manager.id);
    } else {
      newSelectedManagerIds = [];
    }

    setSelectedManagerIds(newSelectedManagerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedManagerIds.indexOf(id);
    let newSelectedManagerIds = [];

    if (selectedIndex === -1) {
      newSelectedManagerIds = newSelectedManagerIds.concat(
        selectedManagerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedManagerIds = newSelectedManagerIds.concat(
        selectedManagerIds.slice(1)
      );
    } else if (selectedIndex === selectedManagerIds.length - 1) {
      newSelectedManagerIds = newSelectedManagerIds.concat(
        selectedManagerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedManagerIds = newSelectedManagerIds.concat(
        selectedManagerIds.slice(0, selectedIndex),
        selectedManagerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedManagerIds(newSelectedManagerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleManagerClick = (managerId) => {
    if (
      currentUser?.userType === Constants.ADMIN ||
      currentUser?.agentPrivileges?.managerEdits ||
      currentUser?.managerPrivileges?.managerEdits
    ) {
      navigateToManagerDetails(managerId);
    }
  };

  const navigateToManagerDetails = (managerId) => {
    navigate('/app/manager/profile', { state: managerId });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {managers
                .slice(page * limit, page * limit + limit)
                .map((manager) => {
                  return (
                    <TableRow
                      hover
                      key={manager.id}
                      selected={selectedManagerIds.indexOf(manager.id) !== -1}
                      onClick={() => handleManagerClick(manager.id)}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar src={manager.avatarUrl} sx={{ mr: 2 }}>
                            {`${getInitials(manager?.firstName)}${getInitials(
                              manager?.lastName
                            )}`}
                          </Avatar>
                          <Typography color="textPrimary" variant="body1">
                            {manager?.firstName.toTitleCase()}{' '}
                            {manager?.middleName?.toTitleCase()}{' '}
                            {manager?.lastName.toTitleCase()}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{manager?.email}</TableCell>
                      <TableCell>{`${manager?.managerBranch?.branchName}`}</TableCell>
                      <TableCell>
                        {moment(manager.createdAt).format('DD/MM/YYYY')}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={managers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ManagerListResults.propTypes = {
  managers: PropTypes.array.isRequired
};

export default ManagerListResults;
