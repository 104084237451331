import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import MainNavbar from './MainNavbar';
import Cookies from 'universal-cookie';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const MainLayoutWrapper = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
});

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get('umf_user');

  useEffect(() => {
    if (!user?.token) {
      navigateToLogin();
    }
  }, []);

  const navigateToLogin = () => {
    navigate('/login');
  };

  return (
    <MainLayoutRoot>
      <MainNavbar />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent>
            <Outlet />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default MainLayout;
