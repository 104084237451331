import { Box, Button } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TransactionListResults from 'src/components/transaction/TransactionListResults';

const Printer = () => {
  const transactionsResults = useSelector((state) => state.app.datastate.transactionsResult);
  useEffect(() => console.log('tresults', transactionsResults), []);
  const navigate = useNavigate();
  const tableRef = useRef();

  const printWithNewWindow = () => {
    const newPrintWindow = window.open('');
    newPrintWindow.document.write(tableRef.current.outerHTML);
    newPrintWindow.print();
    newPrintWindow.close();
  };

  const cancelPrint = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 1
        }}
      >
        <Button variant="contained" onClick={cancelPrint}>
          Cancel
        </Button>
        <Button variant="contained" onClick={printWithNewWindow}>
          Print
        </Button>
      </Box>
      <TransactionListResults ref={tableRef} transactions={transactionsResults} paginate={false} />
    </Box>
  );
};

export default Printer;
