import { Box, Card, CardContent, TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { useMemo } from 'react';
import { Search as SearchIcon } from 'react-feather';

const AccountListToolbar = (props) => {
  const accountsCopy = useMemo(() => props.accounts.map((account) => account), [props.accounts]);

  const doSearch = (searchKey) => {
    if (props.accounts && props.onSearch) {
      if (searchKey.target.value.toLowerCase().match(/\d+/i)) {
        const filterdAccountsByIds = accountsCopy.filter((account) =>
          account.accountId.toString().endsWith(searchKey.target.value.toLowerCase())
        );
        return props.onSearch(filterdAccountsByIds);
      }
      const filteredAccountsByName = accountsCopy.filter((account) =>
        [...account.members, account?.companyName]
          .reduce(
            (prev, member) =>
              `${prev} ${account?.companyName} ${member?.firstName} ${member?.middleName} ${member?.lastName}`,
            ''
          )
          .toLowerCase()
          .includes(searchKey.target.value.toLowerCase())
      );
      props.onSearch(filteredAccountsByName);
    }
  };

  return (
    <Box {...props}>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search account"
                variant="outlined"
                onChange={doSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default AccountListToolbar;
