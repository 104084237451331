import { configureStore } from '@reduxjs/toolkit';
import AppReducer from '../reducers/AppReducer';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage: storage
};

const PersistedReducer = persistReducer(persistConfig, AppReducer);

const Store = configureStore({
  reducer: { app: PersistedReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

const PersistedStore = persistStore(Store);

export { Store, PersistedStore };
