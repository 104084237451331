import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@material-ui/core';
import Cookies from 'universal-cookie';
import CustomerListResults from 'src/components/customer/CustomerListResults';
import CustomerListToolbar from 'src/components/customer/CustomerListToolbar';
import ApiService from 'src/services/ApiService';
import { isTokenExpired } from 'src/utils/Validators';
import Constants from 'src/utils/Constants';
import { BackDropContext } from 'src/utils/BackdropContext';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');
  const backdropContext = useContext(BackDropContext);

  const updateCustomers = useCallback((results) => {
    setDisplayedCustomers(results);
  }, []);

  const navigateToLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const navigateToDashboard = useCallback(() => {
    navigate('/app/dashboard');
  }, [navigate]);

  const sendRequestAndSetCustomers = useCallback(
    async function sendRequestAndSetCustomers() {
      const { status, data } = await ApiService(currentUser.token).GetCustomers({});
      if (isTokenExpired(status)) {
        return navigateToLogin();
      }
      setDisplayedCustomers(data);
      setCustomers(data);
      backdropContext.setShowBackdrop(false);
    },
    [currentUser.token, navigateToLogin]
  );

  const loadCustomers = useCallback(async () => {
    if (!displayedCustomers.length) {
      backdropContext.setShowBackdrop(true);
      try {
        sendRequestAndSetCustomers();
      } catch (error) {
        backdropContext.setShowBackdrop(false);
        alert(error);
      }
    }
  }, [sendRequestAndSetCustomers]);

  // const loadProfileImages = useCallback(async () => {
  //   console.log('call', displayedCustomers);
  //   console.log('indexref', indexRef.current);
  //   if (indexRef.current < 1 && displayedCustomers.length) {
  //     console.log('passed');
  //     try {
  //       const { data } = await ApiService(currentUser.token).GetCustomerProfile({
  //         customer: displayedCustomers[indexRef.current]?._id
  //       });
  //       // console.log('avartar', displayedCustomers[indexRef.current]['avatar']);
  //       displayedCustomers[indexRef.current].avatar = data.avatar;
  //       setDisplayedCustomers(displayedCustomers);
  //     } catch (error) {
  //       console.error(error);
  //     }

  //     indexRef.current = 1;
  //   }
  // }, [displayedCustomers]);

  useEffect(() => {
    if (currentUser.userType === Constants.CUSTOMER) {
      navigateToDashboard();
    }
    if (currentUser.userType === Constants.AGENT && !currentUser.agentPrivileges.customers) {
      navigateToDashboard();
    }
    if (currentUser.userType === Constants.MANAGER && !currentUser.managerPrivileges.customers) {
      navigateToDashboard();
    }
    loadCustomers();
    // loadProfileImages();
  }, [
    currentUser?.agentPrivileges?.customers,
    currentUser?.managerPrivileges?.customers,
    currentUser?.userType,
    loadCustomers,
    // loadProfileImages,
    navigateToDashboard
  ]);

  // const startPollingAndLoadImages = useCallback(() => {
  //   loadProfileImages();
  // }, [displayedCustomers, loadProfileImages]);

  // useEffect(() => startPollingAndLoadImages(), [displayedCustomers]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          <CustomerListToolbar customers={customers} onSearch={updateCustomers} />
          <Box sx={{ pt: 3 }}>
            <CustomerListResults customers={displayedCustomers} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
