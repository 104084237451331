import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import NavItem from '../components/NavItem';
import Cookies from 'universal-cookie';
import userAccessGenerator from 'src/utils/UserAccessGenerator';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookie = new Cookies();
  const user = cookie.get('umf_user');
  const items = userAccessGenerator(
    user.userType,
    user.agentPrivileges || user.managerPrivileges
  );

  useEffect(() => {
    if (!openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, openMobile, onMobileClose]);

  const navigateToUserDetails = () => {
    navigate(`/app/${user.userType.toLowerCase()}/profile`, {
      state: user._id
    });
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          src={user.avatar}
          onClick={navigateToUserDetails}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
            mb: 1
          }}
        />
        <Typography
          color="textPrimary"
          variant="h5"
          onClick={navigateToUserDetails}
        >
          {user.userType === 'ADMIN'
            ? 'System Admin'
            : `${user.firstName} ${user.middleName} ${user.lastName}`}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.userType.toTitleCase()}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              navClick={onMobileClose}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
