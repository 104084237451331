import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { useMemo } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';

const CustomerListToolbar = (props) => {
  const navigate = useNavigate();
  const customersCopy = useMemo(
    () => props.customers.map((customer) => customer),
    [props.customers]
  );

  const navigateToAccountOptions = () => {
    navigate('/app/register/customer');
  };

  const doSearch = (searchKey) => {
    if (props.customers && props.onSearch) {
      const filteredCustomers = customersCopy.filter((customer) =>
        `${customer.firstName} ${
          customer.middleName ? customer.middleName + ' ' : ''
        }${customer.lastName}`
          .toLowerCase()
          .includes(searchKey.target.value.toLowerCase())
      );
      props.onSearch(filteredCustomers);
    }
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={navigateToAccountOptions}
        >
          Add customer
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
                onChange={doSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CustomerListToolbar;
