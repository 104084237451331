import Constants from './Constants';

export const isTokenExpired = (status) => {
  return status === 401;
};

export const getUserType = (user) => {
  if (!user) return null;
  if (Object.keys(user).includes('adminBranch')) {
    return Constants.ADMIN;
  }
  if (Object.keys(user).includes('agentBranch')) {
    return Constants.AGENT;
  }
  if (Object.keys(user).includes('managerBranch')) {
    return Constants.MANAGER;
  }
  if (Object.keys(user).includes('beneficiaryName')) {
    return Constants.CUSTOMER;
  }
  return Constants.CUSTOMER;
};
