if (!String.prototype.toTitleCase) {
  String.prototype.toTitleCase = function () {
    let text = this;
    if (!text) return;

    let textParsed = "";
    for (let t of text) textParsed+=t.toLowerCase();
    if (typeof textParsed != "string") return;

    let transformed = "";
    let wordsInText = textParsed.match(/(\w+)+/ig);
    if (!wordsInText) return textParsed;

    for(let word of wordsInText) transformed+=word.replace(/^./, word[0].toUpperCase())+" ";

    return transformed.trim();
  };
}
