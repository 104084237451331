import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Cookies from 'universal-cookie';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button
} from '@material-ui/core';
import ApiService from 'src/services/ApiService';
import { getApprovalType, parseApprovalDescription } from 'src/utils/Parsers';

const ApprovalListResults = ({ _approvals, onSearch, ...rest }) => {
  const [approvals, setApprovals] = useState(() => _approvals);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const cookies = new Cookies();
  const user = cookies.get('umf_user');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const approveApproval = async (approval) => {
    const { status } = await ApiService(user.token).Approve({
      approvalType: getApprovalType(approval),
      approval: approval._id
    });
    if (status !== 200) {
      return;
    }
    setApprovals((prevApprovals) =>
      prevApprovals.filter((globalApproval) => {
        return globalApproval._id !== approval._id;
      })
    );
  };

  const denyApproval = async (approval) => {
    const { status } = await ApiService(user.token).Deny({
      approvalType: getApprovalType(approval),
      approval: approval._id
    });
    if (status !== 200) {
      return;
    }
    setApprovals((prevApprovals) =>
      prevApprovals.filter((globalApproval) => {
        return globalApproval._id !== approval._id;
      })
    );
  };

  useEffect(() => {
    setApprovals(() => _approvals);
  }, [_approvals]);

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 900 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ cursor: 'pointer' }}>
                <TableCell>Approval Description</TableCell>
                <TableCell>Request date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {approvals
                .slice(page * limit, page * limit + limit)
                .map((approval, index) => (
                  <TableRow hover key={approval._id}>
                    <TableCell>{parseApprovalDescription(approval)}</TableCell>

                    <TableCell>
                      {moment(approval.createdAt).format('DD/MM/YYYY')}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color={'primary'}
                        onClick={() => approveApproval(approval)}
                      >
                        Approve
                      </Button>

                      <Button
                        variant="outlined"
                        color={'secondary'}
                        sx={{ marginLeft: '5px' }}
                        onClick={() => denyApproval(approval)}
                      >
                        Deny
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={approvals.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ApprovalListResults.propTypes = {
  approvals: PropTypes.array.isRequired
};

export default ApprovalListResults;
