import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import getInitials from '../../utils/GetInitials';
import Cookies from 'universal-cookie';
import Constants from 'src/utils/Constants';

const AgentListResults = ({ agents, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const currentUser = cookies.get('umf_user');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleAgentClick = (agentId) => {
    if (
      currentUser?.userType === Constants.ADMIN ||
      currentUser?.agentPrivileges?.managerEdits ||
      currentUser?.managerPrivileges?.managerEdits
    ) {
      navigateToAgentDetails(agentId);
    }
  };

  const navigateToAgentDetails = (agentId) => {
    navigate('/app/agent/profile', { state: agentId });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ cursor: 'pointer' }}>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agents.slice(page * limit, page * limit + limit).map((agent) => (
                <TableRow
                  hover
                  key={agent.id}
                  onClick={() => handleAgentClick(agent.id)}
                  sx={{ ':hover': { cursor: 'pointer' } }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar src={agent.avatarUrl} sx={{ mr: 2 }}>
                        {`${getInitials(agent?.firstName)}${getInitials(
                          agent?.lastName
                        )}`}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {agent?.firstName.toTitleCase()}{' '}
                        {agent?.middleName?.toTitleCase()}{' '}
                        {agent?.lastName.toTitleCase()}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{agent?.email}</TableCell>
                  <TableCell>{`${agent?.agentBranch?.branchName.toTitleCase()}`}</TableCell>
                  <TableCell>
                    {moment(agent.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={agents.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

AgentListResults.propTypes = {
  agents: PropTypes.array.isRequired
};

export default AgentListResults;
