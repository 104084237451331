import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import AccountList from './pages/account/AccountList';
import AgentList from './pages/agent/AgentList';
import ManagerList from './pages/manager/ManagerList';
import CustomerList from './pages/customer/CustomerList';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import RegisterOptions from './pages/registration/RegisterOptions';
import Settings from './pages/Settings';
import BranchList from './pages/branch/BranchList';
import ApprovalList from './pages/ApprovalList';
import Printer from './pages/printer/Printer';

const BranchRegister = React.lazy(() => import('./pages/registration/branch/BranchRegister'));
const ManagerRegister = React.lazy(() => import('./pages/registration/manager/ManagerRegister'));
const AgentRegister = React.lazy(() => import('./pages/registration/agent/AgentRegister'));
const CustomerRegisterOptions = React.lazy(() => import('./pages/registration/customer/CustomerRegisterOptions'));
const PersonalAccount = React.lazy(() => import('src/pages/registration/customer/personal/PersonalAccount'));
const JointAccount = React.lazy(() => import('src/pages/registration/customer/joint/JointAccount'));
const CorporateAccount = React.lazy(() => import('src/pages/registration/customer/corporate/CorporateAccount'));
const Deposit = React.lazy(() => import('src/pages/deposit/Deposit'));
const Withdraw = React.lazy(() => import('src/pages/withdraw/Withdraw'));
const TransactionList = React.lazy(() => import('./pages/TransactionList'));
const AgentProfile = React.lazy(() => import('./pages/agent/AgentProfile'));
const ManagerProfile = React.lazy(() => import('./pages/manager/ManagerProfile'));
const AdminProfile = React.lazy(() => import('./pages/admin/AdminProfile'));
const AgentPrivileges = React.lazy(() => import('./pages/agent/AgentPrivileges'));
const ManagerPrivileges = React.lazy(() => import('./pages/manager/ManagerPrivileges'));
const CustomerProfile = React.lazy(() => import('./pages/customer/CustomerProfile'));
const AccountDetails = React.lazy(() => import('src/pages/account/AccountDetails'));

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '/app', element: <Navigate to="/app/dashboard" /> },
      { path: 'account', element: <AccountList /> },
      { path: 'account/details', element: <AccountDetails />},
      { path: 'customers', element: <CustomerList /> },
      { path: 'agents', element: <AgentList /> },
      { path: 'branches', element: <BranchList /> },
      { path: 'managers', element: <ManagerList /> },
      { path: 'approvals', element: <ApprovalList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'settings', element: <Settings /> },
      { path: 'deposit', element: <Deposit /> },
      { path: 'withdraw', element: <Withdraw /> },
      { path: 'transactions', element: <TransactionList /> },
      { path: 'register', element: <RegisterOptions /> },
      { path: 'agent/privileges', element: <AgentPrivileges /> },
      { path: 'manager/privileges', element: <ManagerPrivileges /> },
      { path: 'agent/profile', element: <AgentProfile /> },
      { path: 'manager/profile', element: <ManagerProfile /> },
      { path: 'admin/profile', element: <AdminProfile /> },
      { path: 'customer/profile', element: <CustomerProfile /> },
      { path: 'register/branch', element: <BranchRegister /> },
      { path: 'register/agent', element: <AgentRegister /> },
      { path: 'register/manager', element: <ManagerRegister /> },
      { path: 'register/customer', element: <CustomerRegisterOptions /> },
      { path: 'register/customer/personal', element: <PersonalAccount /> },
      { path: 'register/customer/joint', element: <JointAccount /> },
      { path: 'register/customer/corporate', element: <CorporateAccount /> },
      { path: 'register/customer/corporate', element: <CustomerRegisterOptions /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register/customer', element: <CustomerRegisterOptions /> },
      { path: 'register/customer/personal', element: <PersonalAccount /> },
      { path: 'register/customer/joint', element: <JointAccount /> },
      { path: 'register/customer/corporate', element: <CorporateAccount /> },
      { path: 'register/customer/corporate', element: <CustomerRegisterOptions /> },
      { path: 'printer', element: <Printer />},
      { path: 'logout', element: <Logout /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
