const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/images/logo.jpeg"
    style={{width: '48px', height: '48px'}}
    {...props}
  />
);

export default Logo;
