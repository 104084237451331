import { Box, Card, CardContent, TextField, InputAdornment, SvgIcon } from '@material-ui/core';
import { useMemo } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { parseApprovalDescription } from 'src/utils/Parsers';

const ApprovalListToolbar = (props) => {
  const approvalsCopy = useMemo(() => props.approvals.map((approval) => approval), [props.approvals]);

  const doSearch = (searchKey) => {
    if (props.onSearch) {
      const filteredApprovals = approvalsCopy.filter((approval) => {
        const parsed = parseApprovalDescription(approval);
        return parsed.includes(searchKey.target.value);
      });
      props.onSearch(filteredApprovals);
    }
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      ></Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search approval"
                variant="outlined"
                onChange={doSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ApprovalListToolbar;
